import React from "react";
import { Container, List, ListItem, Card, CardContent, Typography } from "@mui/material";
import CustomDialog from "../../../mui/dialog";
import { useQuery } from "@tanstack/react-query";
import { TestSessionService } from "../../../../services";
import { ICopiedTextDisplayProps } from "../../../../interfaces";

const CopiedTextDisplay: React.FC<ICopiedTextDisplayProps> = ({ session_id, onChangeModal, isOpen }) => {
  const { getCopiedText } = TestSessionService();
  const copiedTextsRes = useQuery({
    queryKey: ["copied-texts"],
    queryFn: () => getCopiedText({ _id: session_id })
  });

  const copiedTexts = copiedTextsRes.data?.data?.copiedTexts;

  return (
    <CustomDialog
      title="Copied Texts"
      isOpen={isOpen}
      onClose={onChangeModal}
      size="md"
      hideDiscard={true}
      confirmText="Close"
      hideSubmit={true}
    >
      <Container maxWidth="sm" style={{ marginTop: "20px" }}>
        <List>
          {copiedTexts && copiedTexts.map((text: string, index: number) => (
            <ListItem key={index} style={{ display: "flex", alignItems: "flex-start" }}>
              <Typography variant="body1" style={{ marginRight: "10px" }}>
                {index + 1}.
              </Typography>
              <Card variant="outlined" style={{ width: "100%" }}>
                <CardContent>
                  <div dangerouslySetInnerHTML={{ __html: text }} />
                </CardContent>
              </Card>
            </ListItem>
          ))}
        </List>
      </Container>
    </CustomDialog>
  );
};

export default CopiedTextDisplay;
